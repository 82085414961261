import clsx from "clsx";
import * as Icon from "components/Icon";
import { ButtonSize, TextButton } from "components/Button";
import * as CommonIcon from "components/Icon/CommonIcon";
import { IconProps } from "components/Icon/IconProps";
import { DetailCardProgressBarProps } from "components/ProgressBar";
import { TextVariant } from "components/Text";
import { HeadingElement } from "components/Text/Heading";
import { Renameable } from "components/TextInput";
import { everIdProp } from "EverAttribute/EverId";
import { EverIdProp } from "util/type";
import React, {
    Dispatch,
    FC,
    FocusEvent,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from "react";
import "./DetailCard.scss";
import { EverColor } from "tokens/typescript/EverColor";
import "./DetailCard.scss";
import {
    DetailCardBreakdown,
    DetailCardBreakdownItem,
    DetailCardBreakdownItemProps,
} from "./DetailCardBreakdown";
import {
    DetailCardBytesMetadata,
    DetailCardDateMetadata,
    DetailCardVisibilityMetadata,
} from "./DetailCardMetadata";
import { DetailCardThreeDotMenu, DetailCardThreeDotMenuProps } from "./DetailCardThreeDotMenu";
import { Memo, useBrandedCallback } from "hooks/useBranded";
export type { DetailCardBreakdownItemProps } from "./DetailCardBreakdown";
export type { DetailCardThreeDotMenuProps } from "./DetailCardThreeDotMenu";

/**
 * Alternate job statuses that affect the appearance of the {@link DetailCard}
 */
export enum DetailCardStatus {
    DEFAULT = "default",
    ERROR = "error", // adds error icon, makes card border red
    IN_PROGRESS = "in-progress", // adds loading icon
}

export interface DetailCardProps extends EverIdProp {
    /**
     * The body of the card.
     */
    children: ReactNode;
    /**
     * An optional class name to add to the root element of the card.
     */
    className?: string;
    /**
     * Called when the user has finished editing the description, and it is appropriate
     * to commit the change to the backend.
     *
     * No need to call {@link setTitle} from this callback; that's already taken care of.
     */
    commitDescription?: Memo<Dispatch<string>>;
    /**
     * Called when the user has finished editing the title, and it is appropriate
     * to commit the change to the backend.
     *
     * No need to call {@link setTitle} from this callback; that's already taken care of.
     */
    commitTitle?: Memo<Dispatch<string>>;
    /**
     * The contents of the description field (under {@link title}).
     *
     * If both {@code description} and {@link setDescription} are undefined, the description
     * field will be hidden.
     */
    description?: string;
    /**
     * A section for any details or metadata about this card such as date created, size, type, etc.
     */
    detailSection?: ReactElement;
    /**
     * An optional section at the end of the card.
     */
    footer?: ReactNode;
    /**
     * The buttons displayed to the right of the title. Generally a {@code UserBadge}
     * followed by a {@code DetailCard.ThreeDotMenu}
     */
    headerButtons?: ReactNode;
    /**
     * The heading element to use for the card title.
     */
    headingElement: HeadingElement;
    /**
     * An optional ProgressBar.DetailCard.
     */
    progressBar?: ReactElement<DetailCardProgressBarProps> | null;
    /**
     * The setter for the {@link description} state. If not provided, description will be read-only.
     */
    setDescription?: Dispatch<string>;
    /**
     * The setter for the {@link title} state. If not provided, title will be read-only.
     */
    setTitle?: Dispatch<string>;
    /**
     * Change appearance of the card to reflect job status.
     *
     * If set to IN_PROGRESS, display a progress icon to the left of the title.
     *
     * If set to ERROR, display an error icon and make the border of the card red.
     */
    status: DetailCardStatus;
    /**
     * An element displaying other high level info about the card, e.g. Bates range.
     */
    subHeader?: ReactNode;
    /**
     * The title of the card
     */
    title: string;
}

export const DetailCard: FC<DetailCardProps> & {
    Breakdown: FC<{ children: ReactNode }>;
    BreakdownItem: FC<DetailCardBreakdownItemProps>;
    BytesMetadata: FC<{ bytes: number }>;
    DateMetadata: FC<{ date: Date }>;
    ThreeDotMenu: FC<DetailCardThreeDotMenuProps>;
    VisibilityMetadata: FC<{ visible: boolean }>;
} = ({
    children,
    className,
    commitDescription,
    commitTitle,
    description,
    detailSection,
    everId,
    footer,
    headerButtons,
    headingElement,
    progressBar,
    setDescription,
    status = DetailCardStatus.DEFAULT,
    title,
    setTitle,
    subHeader,
}) => {
    // for the title field
    const [editingTitle, setEditingTitle] = useState(false);

    const onRenameDescription = useBrandedCallback(
        (e: FocusEvent<HTMLInputElement>) => commitDescription && commitDescription(e.target.value),
        [commitDescription],
    );
    const onRenameTitle = useBrandedCallback(
        (e: FocusEvent<HTMLInputElement>) => commitTitle && commitTitle(e.target.value),
        [commitTitle],
    );

    // for the description field
    const [editingDescription, setEditingDescription] = useState(false);
    const descriptionRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        editingDescription && descriptionRef?.current?.focus();
    }, [editingDescription]);

    // statusIcon should be a CommonIcon.
    let statusIcon: ReactElement<IconProps> | null = null;
    if (status === DetailCardStatus.IN_PROGRESS) {
        statusIcon = <CommonIcon.Loading size={20} />;
    } else if (status === DetailCardStatus.ERROR) {
        statusIcon = <CommonIcon.Error size={20} />;
    }

    return (
        <section
            className={clsx("bb-detail-card", className, {
                "bb-detail-card--error": status === DetailCardStatus.ERROR,
            })}
            {...everIdProp(everId)}
        >
            {/* TODO: does the header element need to be a direct child of the enclosing <section>? */}
            <div className={"bb-detail-card__header"}>
                {/* TODO: update title and description once Renameable component is updated */}
                <div className={"bb-detail-card__title-bar"}>
                    <div className={"bb-detail-card__title"}>
                        {statusIcon}
                        <Renameable.Heading
                            clickToEdit={!!setTitle}
                            editing={editingTitle}
                            element={headingElement}
                            ellipsify={true}
                            label={"detail card title"}
                            onRename={onRenameTitle}
                            setEditing={setEditingTitle}
                            setValue={setTitle || (() => {})}
                            value={title}
                        />
                    </div>
                    <div className={"bb-detail-card__header-buttons"}>{headerButtons}</div>
                </div>
                {/* only hide the description field if both description and setDescription are unset */}
                {(setDescription || description != null) && (
                    <div className={"bb-detail-card__description"}>
                        {setDescription && !description && !editingDescription ? (
                            <TextButton
                                children={
                                    <Icon.Plus size={16} color={EverColor.EVERBLUE_50}>
                                        Add description
                                    </Icon.Plus>
                                }
                                onClick={() => setEditingDescription(true)}
                                size={ButtonSize.SMALL}
                            />
                        ) : (
                            <Renameable
                                clickToEdit={!!setDescription}
                                editing={editingDescription}
                                setEditing={setEditingDescription}
                                label={"Edit description"}
                                onRename={onRenameDescription}
                                value={description || ""}
                                setValue={setDescription || (() => {})}
                                variant={TextVariant.SMALL}
                                ref={descriptionRef}
                            />
                        )}
                    </div>
                )}
                {detailSection}
            </div>
            {progressBar}
            {subHeader && <div className={"bb-detail-card__sub-header"}>{subHeader}</div>}
            <div className={"bb-detail-card__body"}>{children}</div>
            {footer}
        </section>
    );
};

// job breakdown
DetailCard.Breakdown = DetailCardBreakdown;
DetailCard.BreakdownItem = DetailCardBreakdownItem;

// metadata
DetailCard.BytesMetadata = DetailCardBytesMetadata;
DetailCard.DateMetadata = DetailCardDateMetadata;
DetailCard.VisibilityMetadata = DetailCardVisibilityMetadata;

// three-dot menu
DetailCard.ThreeDotMenu = DetailCardThreeDotMenu;
